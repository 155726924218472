body {
  background-color: #f0f0f0;
  margin: 0;
  font-family: 'Arial', sans-serif;
}

.container {
  margin: auto;
  max-width: 1200px;
}

.chart-card {
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.chart-card h6 {
  color: #555;
}

h4 {
  font-weight: bold;
  color: #444;
}

.row {
  margin: 0;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
  .col-md-4,
  .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
  }
}
.carousel-container {
  display: flex;
  justify-content: space-between; /* Distribute space evenly between carousels */
  gap: 20px; /* Optional: add space between the two carousels */
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.carousel-item {
  flex: 1; /* Each carousel takes equal space */
  min-width: 300px; /* Set a minimum width to avoid too narrow columns */
}
