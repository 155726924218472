.bg-beige {
    background-color: beige;
    padding: 20px; /* Ajusta el padding según sea necesario */
    margin-top: 50px;
    margin-left: 70px;
    margin-right: 70px;
}

input {
    border: 6px solid black; /* Borde de 2 píxeles de grosor y color negro */
    border-radius: 3px; /* Bordes redondeados */
    padding: 5px; /* Espacio interno para el contenido */
}

input:hover {
    border-color: blue; /* Cambio de color del borde a azul al pasar el cursor */
}
/* Estilos para hacer el contenedor responsivo */
@media (max-width: 768px) {
    .bg-beige {
        margin: 10px;
        padding: 10px;
    }
}
